
<script setup>
import { ref, onMounted } from "vue";
import useAuthUser from "@/lib/SupaAuth";
import { useRouter } from "vue-router";
import useShoppingList from "../lib/useShoppingList.js";
import { useRecipe } from '@/lib/useRecipe';

const { getUser } = useAuthUser();
const user = ref(null);
const newItem = ref("");
const userRecipes = ref([]);
const router = useRouter();
const { fetchUserRecipes, loading, error} = useRecipe();
  
const {
  shoppingList,
  shoppingItems,
  getOrCreateShoppingList,
  fetchShoppingItems,
  addShoppingItem,
  deleteShoppingItem,
} = useShoppingList();

onMounted(async () => {
  try {
    console.log("🔄 Ophalen van gebruiker...");
    user.value = await getUser();

    if (!user.value) {
      console.warn("⚠️ Geen ingelogde gebruiker gevonden, doorsturen naar login...");
      return router.push({ name: "landingPage" });
    }

    const list = await getOrCreateShoppingList(user.value.id);

    if (list) {
      await fetchShoppingItems(list.id); // ✅ Zorg ervoor dat shoppingItems correct wordt geladen
      
    }

    console.log('recepten', userRecipes)
    userRecipes.value = await fetchUserRecipes();

  } catch (error) {
    console.error("❌ Fout in onMounted van OverView.vue:", error);
  }
});

const addItem = async () => {
  if (shoppingList.value && newItem.value) {
    await addShoppingItem(shoppingList.value.id, newItem.value);
    newItem.value = "";
  }
};

</script>



<template>
  <div class="min-h-[50vh] container mx-auto ">
    <div class=" my-32 grid xl:grid-cols-2 gap-5">
      
    <div class="flex flex-col gap-5 ">
        <div class="bg-beige h-fit rounded-editRounded py-5">
          <h4 class="text-4xl  text-center "> <i class="fa-solid fa-newspaper fa-md "></i> In Production!</h4>
          <ul class="pl-5 text-xl py-5">
            <li>Login with Social media.</li>
            <li>Share recipes with friends.</li>
            <li>Follow other persons that shares recipes.</li>
            <li>And many many more! stay Tuned!</li>
          </ul>
        </div>

      <div class="bg-beige h-fit rounded-editRounded p-5 ">
        <div v-if="shoppingList">
          <div class=" ">
          <div class="grid sm:grid-cols-2 place-content-center">
            <div class="">
                <h2 class="text-4xl text-center">My Shopping List</h2> 
              </div>
              <div class=" flex justify-center gap-5 items-start">
                <input v-model="newItem" placeholder="Add New item"   />
                <button class='button h-auto' @click="addItem">Add</button>
              </div>
          </div>
          
        </div>
          <ul class="pt-5 ">
            <li v-for="item in shoppingItems" :key="item.id" class=" flex justify-between px-5 ">
             <span> <i class="fa-solid fa-drumstick-bite"></i> <span class="text-xl"> {{ item.name }}</span></span>
              <button  @click="deleteShoppingItem(item.id);" class="text-kruidGroen hover:text-legerGroen transform-all duration-150" ><i class="fa-solid fa-trash fa-lg"></i></button>
            </li>
          </ul>
        </div>
      </div>

      


      <div class="bg-beige h-fit rounded-editRounded p-5 ">
        <h4 class="text-4xl text-center">My Friends </h4>
        <h1 class="text-3xl font-extrabold text-center">Coming soon</h1>
       
      </div>
  </div>
    

    
    <div class="">
        <div class="bg-kruidGroen h-auto rounded-editRounded p-5"> 
          <h4 class="text-4xl font-extrabold text-center row-span-1 text-white mb-5">My Recipes</h4>
          <div v-if="loading" class="h-full w-full text-center text-3xl">Loading...</div>
          <div v-else-if="error" class="bg-red-500">{{ error }}</div>

          <div v-for="recipe in userRecipes" :key="recipe.id" class="recipeView p-10 rounded-editRounded bg-beige mb-10">
            
            <a href='#'>
                <div class='recipe_img_div w-full rounded-editRounded relative'>
                    
                    <ul class="flex gap-2 my-3">
                        <div v-for="ingredient in recipe.ingredients" :key="ingredient.id" class="flex flex-col">
                            <div class="categoryTag">{{ ingredient.name }}</div>
                        </div>
                    </ul>
                    <div class="gradientoverlay"></div>
                    <h3 class="absolute left-5 bottom-5 text-5xl text-black z-10">{{ recipe.name }}</h3>
                    <img src='' alt='afbeelding Recept' class='recipe_img'>
                    
                </div>
                  <div>
                      <h3 class="text-3xl pt-5">Recipe Intro</h3>
                      <p class="text-xl">{{ recipe.intro }}</p>
                  </div>

                 
                  <div class="grid md:grid-cols-3 md:gap-10 pt-5">
                    <p class="text-xl">Difficulty: <span class="text-xl">{{ recipe.difficulty }}</span></p>
                    <p class="text-xl">Time: <span class="text-xl">{{ recipe.make_time }} min</span></p>
                    <p class="text-xl">Persons: <span class="text-xl">{{ recipe.servings }}</span></p>
                  </div>
                
                    <div>
                        <h3 class="text-3xl pt-5">Ingredients</h3>
                        <ul>
                            <li v-for="ingredient in recipe.ingredients" :key="ingredient.id" class="text-xl">
                               Ingredienten hier
                            </li>
                        </ul>
                    </div>

                <div>
                    <h3 class="text-3xl pt-5">Description</h3>
                    <p class="text-xl"> {{ recipe.description }}</p>
                </div>
            </a>

          </div>
        </div>
    </div>

     
      
    </div>
  </div>
</template>
