import { createClient } from "@supabase/supabase-js";

// these can come from an environment variable if desired
// not required however as they are 100% exposed on the client side anyway 
// and that's ok, Supabase expects this (security is provided by Row Level Security)
// const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
// const supabaseKey =  import.meta.env.VITE_SUPABASE_ANON_KEY;
const supabaseUrl = 'https://mssatzlcieosnetbfyoj.supabase.co';
const supabaseKey =  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1zc2F0emxjaWVvc25ldGJmeW9qIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg0MTE5MDIsImV4cCI6MjA1Mzk4NzkwMn0.oXMKs5KRJC7sK4sEuWFw0rLVja6EAH_VGNiUaPQUzc4';

// Maak Supabase client aan
const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true, // ✅ Bewaar sessies
    autoRefreshToken: true, // ✅ Vernieuw tokens automatisch
  },
}); 

// Exporteer direct de client
export { supabase };
