<template>
  <navBar v-if="user" class="mb-20" />
  <router-view />
  <footerBar v-if="user" class="mt-20"/>
</template>

<script setup>
import { ref, onMounted } from "vue";
import navBar from "./components/navBar.vue";
import footerBar from "./components/footerBar.vue";
import { supabase } from "@/lib/useSupaBase"; // ✅ Let op de juiste import

const user = ref(null);

onMounted(async () => {
  // Haal de huidige sessie op
  const { data, error } = await supabase.auth.getSession();
  if (error) {
    console.error("Fout bij ophalen sessie:", error.message);
  } else {
    console.log("Sessie data bij opstarten:", data);
    user.value = data?.session?.user || null;
  }

  // Luister naar veranderingen in de sessie
  supabase.auth.onAuthStateChange((_event, session) => {
    console.log("Auth state changed, nieuwe sessie:", session);
    user.value = session?.user || null;
  });
});

</script>
