<script setup>
</script>

<template>
    <div class="bg-kruidGroen dark:bg-gray-900 ">
        <div class="grid lg:grid-cols-3 gap-10 min-h-60 p-10">
            <div class="footerLogo">
                <img src="" alt="logo">
            </div>

            <div class="">
                <h3 class="dark:text-white text-2xl">Go to</h3>
                <ul>
                    <li><a class='dark:text-white' href="#"> Link naar </a></li>
                    <li><a class='dark:text-white' href="#"> Link naar </a></li>
                    <li><a class='dark:text-white' href="#"> Link naar </a></li>
                    <li><a class='dark:text-white' href="#"> Link naar </a></li>
                </ul>
            </div>

            <div class="">
                <h3 class="dark:text-white text-2xl">More links</h3>
                <ul>
                    <li><a class='dark:text-white' href="#"> Link naar </a></li>
                    <li><a class='dark:text-white' href="#"> Link naar </a></li>
                    <li><a class='dark:text-white' href="#"> Link naar </a></li>
                    <li><a class='dark:text-white' href="#"> Link naar </a></li>
                </ul>
            </div>

            
        </div>
        <div class="bg-legerGroen dark:bg-gray-600 text-center ">
               <span class="text-2xl text-white">&#169;</span> <a href="https://swenssoftware.nl/" class='text-white'>Made by SwensSoftware</a>
        </div>
    </div>
</template>