import { ref } from "vue";
import { supabase } from "./useSupaBase"; // Zorg ervoor dat je de juiste import gebruikt

export default function useShoppingList() {
  const shoppingList = ref(null);
  const shoppingItems = ref([]);

  /**
   * Haalt of maakt een shoppinglist voor de ingelogde gebruiker
   */
  const getOrCreateShoppingList = async (userId) => {
    if (!userId) throw new Error("Geen gebruiker gevonden");

    // Check of de gebruiker al een shoppinglist heeft
    const { data: existingList, error } = await supabase
      .from("shopping_list")
      .select("id")
      .eq("user_id", userId)
      .single();

    if (error && error.code !== "PGRST116") {
      // PGRST116 = geen resultaten, wat betekent dat de gebruiker nog geen lijst heeft
      throw error;
    }

    if (existingList) {
      shoppingList.value = existingList;
      return existingList;
    }

    const userUUID = userId.toString(); // Zorgt ervoor dat de ID als string wordt opgeslagen
    const { data: newList, error: createError } = await supabase
      .from("shopping_list")
      .insert([{ user_id: userUUID }])
      .select()
      .single();
    if (createError) throw createError;

    shoppingList.value = newList;
    return newList;
  };

  /**
   * Haal alle items op uit de shoppinglist van de gebruiker
   */
  const fetchShoppingItems = async (shoppingListId) => {
    if (!shoppingListId) throw new Error("Geen shopping list ID gevonden");

    const { data, error } = await supabase
      .from("shopping_item")
      .select("*")
      .eq("shopping_list_id", shoppingListId);

    if (error) throw error;

    shoppingItems.value = data;
    return data;
  };

  /**
   * Voeg een nieuw item toe aan de shoppinglist
   */
  const addShoppingItem = async (shoppingListId, name, quantity = 1) => {
    if (!shoppingListId) throw new Error("Geen shopping list ID gevonden");
    if (!name) throw new Error("Item naam is verplicht");

    const { data, error } = await supabase
      .from("shopping_item")
      .insert([{ shopping_list_id: shoppingListId, name, quantity }])
      .select()
      .single();

    if (error) throw error;
    
    shoppingItems.value = [...shoppingItems.value, data];
    return data;
  };

  /**
   * Update een shopping item (bijv. hoeveelheid aanpassen)
   */
  const updateShoppingItem = async (itemId, updates) => {
    if (!itemId) throw new Error("Geen item ID gevonden");

    const { data, error } = await supabase
      .from("shopping_item")
      .update(updates)
      .eq("id", itemId)
      .select()
      .single();

    if (error) throw error;

    shoppingItems.value = shoppingItems.value.map((item) =>
      item.id === itemId ? data : item
    );

    return data;
  };

const deleteShoppingItem = async (itemId) => {
  if (!itemId) {
    console.error("❌ Geen item ID gevonden!");
    return;
  }

  console.log(`🗑️ Probeer item te verwijderen met ID:`, itemId, typeof itemId);

  const { error } = await supabase
    .from("shopping_item")
    .delete()
    .eq("id", itemId);

  if (error) {
    console.error("❌ Fout bij verwijderen van item:", error);
    return;
  }

  console.log(`✅ Item met ID ${itemId} succesvol verwijderd uit database!`);

  // UI bijwerken
  shoppingItems.value = shoppingItems.value.filter((item) => item.id !== itemId);
};

  // // Wis alle items 
  // const deleteAllItemsFromList = async (shoppingListId) => {
  //   if (!shoppingListId) {
  //     console.error("❌ Geen shopping list ID gevonden!");
  //     return;
  //   }
  
  //   console.log(`🗑️ Verwijderen van alle items in shopping list ${shoppingListId}...`);
  
  //   const { error } = await supabase
  //     .from("shopping_item")
  //     .delete()
  //     .eq("shopping_list_id", shoppingListId);
  
  //   if (error) {
  //     console.error("❌ Fout bij verwijderen van items:", error);
  //     return;
  //   }
  
  //   console.log(`✅ Alle items in shopping list ${shoppingListId} zijn verwijderd!`);
  
  //   shoppingItems.value = [];
  // };

  return {
    shoppingList,
    shoppingItems,
    getOrCreateShoppingList,
    // deleteAllItemsFromList,
    fetchShoppingItems,
    addShoppingItem,
    updateShoppingItem,
    deleteShoppingItem,
  };
}
