import { ref } from 'vue';
import { supabase } from "./useSupaBase"; // ✅ Enige juiste import

export default function useAuthUser() {
  const user = ref(null); // `user` state using ref

  /**
   * Login with email and password (using signInWithPassword)
   */
  const login = async ({ email, password }) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) throw new Error(`Login failed: ${error.message}`);

    user.value = data.user; // Sla de ingelogde gebruiker op
    return data.user;
  };

  /**
   * Login with Google, GitHub, etc. (using signInWithOAuth)
   */
  const loginWithSocialProvider = async (provider) => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider, // provider should be a string like 'google', 'github', etc.
    });
    if (error) throw new Error(`Social login failed: ${error.message}`);
    return data.user;
  };

  /**
   * Logout
   */
  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    user.value = null; // clear the user state
  };

  /**
   * Check if the user is logged in or not
   */
  const isLoggedIn = async () => {
    const currentUser = await getUser();
    return !!currentUser; // Return true als de gebruiker bestaat
  };

  const getUser = async () => {
    const { data, error } = await supabase.auth.getUser();

    if (error) {
      console.warn("Geen sessie gevonden:", error.message);
      return null; // Geef null terug in plaats van een fout
    }

    return data.user;
  };

  /**
   * Register
   */
  const register = async ({ email, password, ...meta }) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: meta,
        emailRedirectTo: `${window.location.origin}/me?fromEmail=registrationConfirmation`,
      },
    });
    if (error) throw error;
    user.value = data.user; // update the user state
    return data.user;
  };

  /**
   * Update user email, password, or meta data
   */
  const update = async (data) => {
    const { data: updatedUser, error } = await supabase.auth.updateUser(data);
    if (error) throw error;
    user.value = updatedUser; // update the user state
    return updatedUser;
  };


  // Huidige gebruiker ophalen
const useUser = () => {
  const user = ref(null);

  const fetchUser = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (error) console.error(error);
      user.value = data?.user ?? null;
  };

  fetchUser(); // Roep deze functie direct aan bij laden

  return user;
};

  /**
   * Send user an email to reset their password
   */
  const sendPasswordResetEmail = async (email) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) throw new Error(`Reset password failed: ${error.message}`);
  };

  return {
    user,
    login,
    loginWithSocialProvider,
    isLoggedIn,
    logout,
    register,
    update,
    sendPasswordResetEmail,
    getUser,
    useUser,
  };
}
