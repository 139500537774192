import { ref } from 'vue';
import { supabase } from './useSupaBase';

export function useRecipe() {
    const loading = ref(false);
    const error = ref(null);

    const fetchUserRecipes = async () => {
        loading.value = true;
        error.value = null;
    
        try {
            // ✅ Haal de ingelogde gebruiker op
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) {
                error.value = "Je moet ingelogd zijn om recepten te bekijken.";
                return [];
            }
    
            // ✅ Haal recepten met ingrediënten op via JOINS
            const { data: recipes, error: fetchError } = await supabase
                .from('recipe')
                .select(`
                    *,
                    recipe_ingredients(ingredient_items(id, ingredient_name))  -- ✅ Haal ingrediënten op via de relatie
                `)
                .eq('user_id', user.id);
    
            if (fetchError) throw fetchError;
    
            // ✅ Data goed structureren
            const formattedRecipes = recipes.map(recipe => ({
                ...recipe,
                ingredients: recipe.recipe_ingredients.map(entry => ({
                    id: entry.ingredient_items.id,
                    name: entry.ingredient_items.ingredient_name
                }))
            }));
    
            return formattedRecipes || [];
    
        } catch (err) {
            error.value = err.message;
            return [];
    
        } finally {
            loading.value = false;
        }
    };
    
    


    const addRecipe = async (recipe, imageFile, ingredients, categories) => {
        loading.value = true;
        error.value = null;
    
        try {
            // ✅ **Stap 0: Haal de gebruiker op**
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) {
                error.value = "Je moet ingelogd zijn om een recept toe te voegen.";
                return;
            }
    
            // ✅ **Stap 1: Voeg het recept toe**
            const { data: recipeData, error: recipeError } = await supabase
                .from('recipe')
                .insert([{
                    name: recipe.name,
                    intro: recipe.intro,
                    description: recipe.description,
                    servings: recipe.servings,
                    difficulty: recipe.difficulty,
                    make_time: recipe.make_time,
                    user_id: user.id // ✅ Correct user_id opslaan
                }])
                .select()
                .single();
    
            if (recipeError) throw recipeError;
            const recipeId = recipeData.id;
    
            // ✅ **Stap 2: Voeg ingrediënten toe**
            const ingredientEntries = [];
            for (const ingredient of ingredients) {
                let { data: existingIngredient } = await supabase
                    .from('ingredient_items')
                    .select('id')
                    .eq('ingredient_name', ingredient)
                    .maybeSingle();
    
                if (!existingIngredient) {
                    const { data: newIngredient } = await supabase
                        .from('ingredient_items')
                        .insert([{ ingredient_name: ingredient }])
                        .select()
                        .single();
                    existingIngredient = newIngredient;
                }
    
                ingredientEntries.push({
                    recipe_id: recipeId,
                    ingredient_id: existingIngredient.id,
                    amount: '' // ✅ Voeg hoeveelheid toe indien nodig
                });
            }
            if (ingredientEntries.length > 0) {
                await supabase.from('recipe_ingredients').insert(ingredientEntries);
            }
    
            // ✅ **Stap 3: Voeg categorieën toe**
            const categoryEntries = [];
            for (const category of categories) {
                if (!Array.isArray(categories)) {
                    console.error("categories is not een array:", categories);
                    return;
                  }
                let { data: existingCategory } = await supabase
                    .from('categories')
                    .select('id')
                    .eq('name', category)
                    .maybeSingle();
    
                if (!existingCategory) {
                    const { data: newCategory } = await supabase
                        .from('categories')
                        .insert([{ name: category }])
                        .select()
                        .single();
                    existingCategory = newCategory;
                }
    
                categoryEntries.push({
                    recipe_id: recipeId,
                    category_id: existingCategory.id
                });
            }
            if (categoryEntries.length > 0) {
                await supabase.from('recipe_category').insert(categoryEntries);
            }
    
            // ✅ **Stap 4: Upload de afbeelding en koppel deze**
            let imageUrl = null;
            if (imageFile) {
                const filePath = `recipes/${recipeId}-${imageFile.name}`;
                const { error: imageError } = await supabase
                    .storage
                    .from('recipe_img')
                    .upload(filePath, imageFile);
    
                if (imageError) throw imageError;
    
                // Verkrijg de publieke URL van de geüploade afbeelding
                const { data: publicUrlData } = supabase
                    .storage
                    .from('recipe_img')
                    .getPublicUrl(filePath);
                imageUrl = publicUrlData.publicUrl;
    
                // Voeg de afbeelding toe aan de `images` tabel
                const { data: imageData, error: imageInsertError } = await supabase
                    .from('images')
                    .insert([{ url: imageUrl, alt_text: recipe.name }])
                    .select()
                    .single();
    
                if (imageInsertError) throw imageInsertError;
    
                // Koppel de afbeelding aan het recept
                await supabase
                    .from('recipe')
                    .update({ img_id: imageData.id })
                    .eq('id', recipeId);
            }
    
            return { success: true, recipeId, imageUrl };
    
        } catch (err) {
            error.value = err.message;
            return { success: false, error: err.message };
    
        } finally {
            loading.value = false;
        }
    };

    return {
        fetchUserRecipes,
        useRecipe,
        addRecipe,
        loading,
        error
    };
}
